<template>
	<div class="footer section">
		<div class="info">
			<div class="center">We simplify IT and put you on a Straight Path to Growth, Productivity and Success!</div>
			<div class="center">MSP located in<br> Houston, TX<hr style="max-width: 50%; margin-left: auto; margin-right: auto;" /> (832) 271-1151</div>
			<div class="center">Email Us At <br>info@straightpathit.com</div>
			<div class="center">Connect With Us On<br><a style="color: white;" href="https://www.linkedin.com/company/straight-path-it-management?trk=public_profile_experience-item_profile-section-card_subtitle-click">LinkedIn</a></div>
		</div>
		<center style="width: 100%;">
			<a href="https://www.microsoft.com/en-us/teals"><img class="" src="../assets/logos/TEALS.png" /></a>
			<img class="" src="../assets/minoritylogo.png" />
			<img class="" src="../assets/lgbtlogo.png" />
		</center>
	</div>		
</template>
<script>
export default {
	name: 'bottom'
}
</script>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap" rel="stylesheet"> 
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap" rel="stylesheet"> 
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"> 
<style lang="scss">
	@import '../assets/style.scss';
</style>