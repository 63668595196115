<template>
	<div class="wrapper">
		<div class="navigation section" :class="{ 'onScroll': !view.topOfPage}">
			<img class="logo" :src="logo" />
			<div class="mobile nav">
				<i class="fas fa-bars" v-if="isHidden" v-on:click="isHidden = false;"></i>
				<router-link v-if="!isHidden" to="/">Home</router-link>
				<router-link v-if="!isHidden" to="/services">Services</router-link>
				<a v-if="!isHidden" href="mailto:info@straightpathit.com">Contact Us</a>
				<a v-if="!isHidden" href="tel:832-271-1151">(832) 271-1151</a>
			</div>
			<div class="pc nav">
				<router-link to="/">Home</router-link>
				<router-link to="/services">Services</router-link>
				<a href="mailto:info@straightpathit.com">Contact Us</a>
				<a href="tel:832-271-1151">(832) 271-1151</a>
				<!-- <a href="#"><i class="fa fa-search"></i></a> -->
			</div>
		</div>
		<div class="hero section">
			<!-- eslint-disable -->
			<h1>Your Straight Path to <transition name="slide-fade" mode="out-in"><div :key="index">{{ tagline[index] }}</div></transition></h1>
			<div class="cta"><a href="mailto:support@straightpathit.com">Get Support Now</a></div>
		</div>
		<div class="offers section"><!-- used to be teal-classed -->
			<div class="offerhead"><h2>What Can <span class="blue">Blue</span> Do For You?</h2></div>
			<div class="offer" data-aos="fade-down" data-aos-once="true" data-aos-delay="0">
				<router-link to="/service/it-managed-services">
					<img src="../assets/icons/managedservices.png" />
					<span><br>IT Managed Services</span>
				</router-link>
			</div>
			<div class="offer" data-aos="fade-down" data-aos-once="true" data-aos-delay="50">
				<router-link to="/service/professional-services"><img src="../assets/icons/professionalservices.png" /><br>
					<span>Professional Services</span>
				</router-link>
			</div>
			<div class="offer" data-aos="fade-down" data-aos-once="true" data-aos-delay="100">
				<router-link to="/service/cloud-services"><img src="../assets/icons/cloudservices.png" /><br>
					<span>Cloud<br />Services</span>
				</router-link>
			</div>
			<div class="offer" data-aos="fade-down" data-aos-once="true" data-aos-delay="150">
				<router-link to="/service/business-services"><img src="../assets/icons/businessservices.png" /><br>
					<span>Business<br />Services</span>
				</router-link>
			</div>
			<div class="offer" data-aos="fade-down" data-aos-once="true" data-aos-delay="200">
				<router-link to="/service/web-services"><img src="../assets/icons/webservices.png" /><br>
					<span>Web<br />Services</span>
				</router-link>
			</div>
		</div>
		<div class="partners section white" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-once="true" > <!-- used to be white-classed -->
			<div class="bgimg">&nbsp;</div>
			<div class="statement"><!-- <h2>Faster, Safer, Simpler</h2><br><p>Technology should never stand in your way. StraightPath IT Management specializes in helping small businesses keep their data secure and networks scalable as they grow. Our offices are based right here in the US, and come at an all-inclusive, flat monthly rate.</p>-->
				<h2 style="margin-top: -10px; font-weight: bold;">Streamlining Your Technology Services</h2>
				<p>We don’t start with solutions, we start with questions. Lots of them. <span class="blue">Then we listen. </span></p>
				<p>Service providers have a well-earned reputation for selling answers in search of questions. Your business gets solutions you don't need to problems you don't even have. They load you with bloat that holds your business back, and then productivity begins to suffer.</p>
				<p><span class="blue">We're different.</span> We dig deep to understand your business, share your vision, and help guide your success. All those things you hate about your technology service experience? We understand, and we're doing something about it.</p>
				<p>We provide solutions that work in the real world, not just in sales proposals. We want to foster your growth. <span class="blue">We want to earn your trust.</span> We don't want just "clients"—we want partners.</p>
			</div>
		</div>
		<div class="certs section">
			<img class="i1" src="../assets/logos/aws.png" />
			<img class="i2" src="../assets/logos/azure.png" />
			<!-- <img class="i2" src="../assets/logos/TEALS.png" /> -->
			<img class="i3" src="../assets/logos/google.png" />
			<img class="i4" src="../assets/logos/windowslogo.png" />
			<img class="i5" src="../assets/logos/microsoft.png" />
		</div>
		<div class="slides section">
			<div class="swiper">
				<h1 class="dyk" style="border: 1px solid #5c7d8a; border-width: 0px 1px; border-radius: 25px">Did You Know? <i class="far fa-lightbulb"></i></h1>
				<!-- https://www.ic3.gov/Media/Y2020/PSA200406 --> 
				<!-- https://www.securitymagazine.com/articles/87787-hackers-attack-every-39-seconds --> 
				<!-- https://www.csoonline.com/article/3153707/top-cybersecurity-facts-figures-and-statistics.html Almost all malware comes via email -->
				<!-- Slideshow container -->
				<!-- Slider main container -->
				<swiper
					:autoplay="{ delay: 6400, disableOnInteraction: false }"
					:effect="'coverflow'" 
					:grabCursor="true" 
					:centeredSlides="true" 
					:slidesPerView="'auto'" 
					:coverflowEffect="{
						'rotate': 50,
						'stretch': 0,
						'depth': 100,
						'modifier': 1,
						'slideShadows': true
					}" :pagination="true"
				>
					<swiper-slide>
						<h1>Business email compromise scams have cost US businesses <span class="hi">more than $2 Billion</span>, according to the FBI</h1>
						<div>We Can Help Protect Your Profits <br></div><router-link to="/services">Learn More</router-link>
					</swiper-slide>
					<swiper-slide>
						<h1>A Clark School study discovered the average computer with internet access is targeted by hackers <span class="hi">over 2,000 times a day</span></h1>
						<div>Strengthen Your Network Security With Our Services <br></div><router-link to="/services" class="swiper-no-swiping">Learn More</router-link>
					</swiper-slide>
					<swiper-slide>
						<h1>Attacks on Internet-of-Things devices <span class="hi">doubled</span> in the first half of 2021 compared to last year</h1>
						<div>We Provide Holistic Protection For Your Entire Network <br></div><router-link to="/services" class="swiper-no-swiping">Learn More</router-link>
					</swiper-slide>
					<swiper-slide>
						<h1><span class="hi">60% of breaches</span> involved vulnerabilities for with a software update was available, but not applied</h1>
						<div>We Can Keep You Up-to-Date <br></div><router-link to="/services" class="swiper-no-swiping">Learn More</router-link>
					</swiper-slide>
					<swiper-slide>
						<h1>The average cost to an enterprise hit by a data breach is <span class="hi">$3.92 million</span></h1>
						<div>Let's Secure Your Data Today<br></div><router-link to="/services" class="swiper-no-swiping">Learn More</router-link>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<bottom></bottom>		
	</div>
</template>
<script>
/*
// import Swiper JS
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
// import Swiper styles
import 'swiper/swiper-bundle.css';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);
*/
// Import Swiper Vue.js components
import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import bottom from '@/components/bottom.vue'

// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

export default {
	title: "Straight Path IT Management",
	name: 'HelloWorld',
	components: {
		Swiper,
		SwiperSlide,
		bottom
    },
	data(){
		return{
			index: 0,
			tagline: [
				'Growth',
				'Productivity',
				'Success'
			],
			view: {
				topOfPage: true
			},
			isHidden: true,
			logo: require('../assets/logo.png')
		}
    },
    mounted() {
		this.update();
    },
	methods: {
		update () {
			setTimeout(() => {
				this.index += 1;
				if (this.index >= this.tagline.length) {
					this.index = 0;
				}
				this.update()
			}, 1750);
		},
		onSwiper(swiper) {
			console.log(swiper);
		},
		onSlideChange() {
			// console.log('slide change');
		},
		handleScroll(){
			// console.log(window.pageYOffset);
			if(window.pageYOffset > 50){
				if(this.view.topOfPage){
					this.view.topOfPage = false;
					this.logo = require('../assets/logo2.png');
				} 
			}
			else {
				if(!this.view.topOfPage){
					this.view.topOfPage = true; 
					this.logo = require('../assets/logo.png'); 
				}
			}
		}
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll)
	},
}
</script>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap" rel="stylesheet"> 
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap" rel="stylesheet"> 
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"> 
<style lang="scss" scoped>
	@media only screen and (min-width: 64em){
		.navigation{
			position: fixed;
			top: 0px; 
			left: 0px;
			right: 0px;
			z-index: 99;

			.logo{
				width: 500px;
				height: auto;
			}
			transition: all 250ms ease;
			*{
				transition: all 250ms ease-out;
			}
		}
		.onScroll .logo{
			width: 250px !important;
			height: 60px !important;
		}
		.spacer{
			min-height: 150px;
		}
	}
</style>